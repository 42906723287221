import { IntegrationsPageLayout } from "../../components/IntegrationsPageLayout";
import { Chains } from "./components/Chains";
import { DivideSection } from "../../components/DivideSection";
import React from "react";
import { Tools } from "./components/Tools";
import { BigDivideSection } from "../../components/BigDivideSection";

export const Integrations = () => {
  return (
    <IntegrationsPageLayout
      bgImage="/bg/integrations-bg.png"
      title="Integrations"
      description="Project Zero seamlessly integrates with any blockchain, AI Agent infrastructure and data tool."
      cta={{
        label: "Become a partner",
        link: "https://forms.fillout.com/t/7vQMGrJGoFus",
        icon: "/icons/arrow-right-up.svg",
      }}
    >
      <Chains />
      <DivideSection side="full" />
      <Tools />
      <BigDivideSection side="left" className="hidden sm:grid" />
      <DivideSection side="full" className="grid sm:hidden" />
    </IntegrationsPageLayout>
  );
};
