import { Article } from "../../types";

export const parseBlogData = (
  data: any,
): {
  previewArticles: any;
  heroArticle: Article;
  articles: Article[];
} => {
  const parsed = data.items[0].fields.posts.filter(({ fields }: any) => fields);

  const heroArticleData = parsed.find((p: any) => p?.fields?.isHero);
  const articlesData = parsed.filter(
    (p: any) => p.sys.id !== heroArticleData.sys.id,
  );

  const heroArticle: Article = {
    id: heroArticleData.sys.id,
    title: heroArticleData.fields?.title ?? "",
    date: heroArticleData.fields.createdAt ?? "",
    content: heroArticleData.fields.content.content ?? "",
    image: heroArticleData.fields.image.fields.file.url ?? "",
  };

  const previewArticles = parsed.map((a: any) => ({
    id: a.sys.id,
    title: a.fields?.title ?? "",
    date: a.fields.createdAt ?? "",
    previewText:
      a.fields.content.content.find((c: any) => c.nodeType === "paragraph")
        .content[0].value ?? "",
    image: a.fields.image.fields.file.url ?? "",
  }));

  const articles: Article[] = articlesData.map((a: any) => ({
    id: a.sys.id,
    title: a.fields.title ?? "",
    date: a.fields.createdAt ?? "",
    previewText:
      a.fields.content.content.find((c: any) => c.nodeType === "paragraph")
        .content[0].value ?? "",
    image: a.fields.image.fields.file.url ?? "",
  }));
  return { heroArticle, articles, previewArticles };
};
