export interface ChainMockProps {
  icon: string;
  title: string;
  description: string;
  badge?: string;
}

export const chainMocks: ChainMockProps[] = [
  {
    icon: "/icons/chains/ethereum.svg",
    title: "Ethereum",
    description: "Mainnet, Sepolia",
  },
  {
    icon: "/icons/chains/optimism.svg",
    title: "Optimism",
    description: "Mainnet",
  },
  {
    icon: "/icons/chains/bitlayer.svg",
    title: "Bitlayer",
    description: "Mainnet",
  },
  {
    icon: "/icons/chains/arbitrum.svg",
    title: "Arbitrum",
    description: "Mainnet",
  },
  { icon: "/icons/chains/base.svg", title: "Base", description: "Mainnet" },
  { icon: "/icons/chains/bnb.svg", title: "BNB Chain", description: "Mainnet" },
  { icon: "/icons/chains/peaq.svg", title: "Peaq", description: "Mainnet" },
  {
    icon: "/icons/chains/avalanche.svg",
    title: "AVAX",
    description: "Mainnet",
  },
  {
    icon: "/icons/chains/polygon.svg",
    title: "Polygon",
    description: "Mainnet",
  },
  { icon: "/icons/chains/kava.svg", title: "Kava", description: "Mainnet" },
  { icon: "/icons/chains/scroll.svg", title: "Scroll", description: "Mainnet" },
  { icon: "/icons/chains/vanar.svg", title: "Vanar", description: "Mainnet" },
  {
    icon: "/icons/chains/ink.svg",
    title: "Ink",
    description: "Mainnet",
  },
  {
    icon: "/icons/chains/aptos.svg",
    title: "Aptos",
    description: "Mainnet",
  },
  {
    icon: "/icons/chains/citrea.svg",
    title: "Citrea",
    description: "Testnet",
  },
  {
    icon: "/icons/chains/gnosis.svg",
    title: "Gnosis",
    description: "Mainnet",
  },
  {
    icon: "/icons/chains/manta-pacific.svg",
    title: "Manta Pacific",
    description: "Mainnet",
  },
  {
    icon: "/icons/chains/sonic.svg",
    title: "Sonic",
    description: "Mainnet",
  },
  {
    icon: "/icons/chains/solana.svg",
    title: "Solana",
    description: "Mainnet",
    badge: "Coming soon",
  },
  {
    icon: "/icons/chains/sui.svg",
    title: "Sui",
    description: "Mainnet",
    badge: "Coming soon",
  },
];

export const toolsMocks: ChainMockProps[] = [
  {
    icon: "/icons/tools/webhook.svg",
    title: "Webhook",
    description: "Receive real-time data updates.",
  },
  {
    icon: "/icons/tools/kafka.svg",
    title: "Kafka",
    description: "Stream data for high-throughput systems.",
  },
  {
    icon: "/icons/tools/s3.svg",
    title: "Amazon S3",
    description: "Store data securely in the cloud.",
  },
  {
    icon: "/icons/tools/postgres.svg",
    title: "Postgres",
    description: "Manage structured data efficiently.",
    badge: "Coming soon",
  },
];
