import { ChainMockProps } from "../helpers";
import { Link } from "react-router-dom";
import { clsx } from "clsx";

export interface IntegrationsCardProps extends ChainMockProps {
  link: string;
  index?: number;
}

export const IntegrationsCard = ({
  link,
  title,
  badge,
  icon,
  description,
  index,
}: IntegrationsCardProps) => {
  const removeTopBorder =
    (index && index < 3) || title === "Ethereum" || title === "Webhook";

  return (
    <div className="flex flex-col flex-1 h-full">
      <div className="flex sm:flex-col sm:flex-1 h-full">
        <div className="sm:hidden flex flex-none w-6 border-b" />
        <Link
          to={link}
          className={clsx(
            "sm:p-10 p-6 flex-col flex-1 group border w-full border-t-0 sm:border-t-[1px] bg-white hover:bg-gradient-to-r hover:from-white hover:via-primary-400/10 hover:to-primary-400/40 transition-all duration-150 flex justify-between",
            {
              "sm:!border-t-0": removeTopBorder,
              "sm:!border-b-0":
                title.includes("Request") || title === "Postgres",
            },
          )}
        >
          <div className="flex flex-col flex-1">
            <div className="flex justify-between items-center">
              <div className="sm:w-12 sm:h-12 w-10 h-10 flex flex-none items-center justify-center rounded-full">
                <img
                  src={icon}
                  alt="card-icon"
                  className="sm:w-12 sm:h-12 w-10 h-10"
                  width="auto"
                  height="auto"
                />
              </div>
              {badge && (
                <div className="flex whitespace-nowrap bg-white h-fit px-3 py-2 rounded-full border border-primary-400">
                  <h5 className="text-base leading-none">{badge}</h5>
                </div>
              )}
            </div>

            <div className="sm:mt-6 mt-5 text-xl tracking-tighter sm:text-2xl font-medium text-neutral-950">
              {title}
            </div>
            <p className="sm:mt-4 mt-2 text-sm leading-none tracking-tight text-neutral-500 font-medium sm:text-base">
              {description}
            </p>
          </div>
        </Link>
        <div className="sm:hidden flex flex-none w-6 ml-auto border-b" />
      </div>
      <div
        className={clsx("flex h-4 sm:hidden border-b", {
          "border-b-0": title.includes("Request") || title === "Postgres",
        })}
      >
        <div className="flex flex-none w-6" />
        <div className="flex flex-1 border-r border-l" />
        <div className="flex flex-none w-6" />
      </div>
    </div>
  );
};
